<template>
  <div class="order-management">
    <!-- <div class="prediction">
     <div style="padding: 8px">
      <nqForm
      :submitText="submitText"
      @submit="submitForm"
      @cancel="cancelForm"
      :values="formValue"
      :formList="[
        {
          label: '原始密码',
          key: 'password',
          type: 'input',
          placeholder: '请输入原始密码',
        },
        {
          label: '新密码',
          key: 'newPassword',
          type: 'input',
          placeholder: '请输入新密码',
        },
        {
          label: '确认密码',
          key: 'relPassword',
          type: 'input',
          placeholder: '请再次输入新密码',
        }
      ]"
    ></nqForm>
    </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'login',
  data() {
    return {
      // showDetail: false,
      // formValue: {
      //   password: '',
      //   newPassword: '',
      //   relPassword: ''
      // }
    }
  },

  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
</style>